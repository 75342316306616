import '../index.css'
import {React} from 'react'
import {
  Link
} from "react-router-dom";
import wiggle from '../imgs/wiggle.png';



export default function Submit () {


    return (
      <>
    <div className='grid justify-items-center' style={{ padding: 30 }}>
    <article className="prose ">
      <h1>💖 Be our bestie 💖</h1>
      <strong>Calling all Popular Girls!</strong>
      <p>We are now accepting submissions for future issues and we want to hear from you!</p>
      <p>Send us your….
        <br/>
        🌸 Photography
        <br/>
        🌸 Graphic Design
        <br/>
        🌸 Pop Culture Think Pieces
        <br/>
        🌸 Multimedia Works of Art
        <br/>
        🌸 Recently Uncovered, Painfully Sincere Diary Entries
        <br/>
        🌸 Top 5 lists
        <br/>
        🌸 Odes to Your Current Obsession
        <br/>
        🌸 Etc…
        <br/>
        </p>

      <p>If you have something that you think is soooo Popular Girl, we want to see it!
      Send your submissions to populargirlpress@gmail.com or using the link below. All submissions are considered and appreciated, not all will be included.</p>
      <p>P.S. besties.. please don't send us any viruses or anything weird</p>
    <br/>
    </article>  
    <button className="btn btn-primary">
  <Link to="https://forms.gle/eCaTwTEUzvSTZf6u6" target="_blank" rel="noopener noreferrer">Submit</Link>
</button>    
    </div>
<img alt='divider' src={wiggle} style={{ width: 'auto', height: 'auto' }} />
      <br/>  
      </>
    )
}
