
import React from "react";
import {
  Routes,
  Route,
  Link
} from "react-router-dom";
import About from "./components/about";
import Shop from "./components/shop";
import Home from "./components/home";
import logo from "./imgs/logo.png";
// import Footer from "./components/footer";
import Submit from "./components/submit";

function App() {
  return (
  <>
    <div className="grid justify-items-center">
    <div className= "flex-1">
    <Link to="/"><img className="object-scale-down h-48 w-96" src={logo} alt="Popular Girl Home"/></Link>
    </div>
     <ul className="menu menu-horizontal bg-base-200 rounded-box">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/shop">Shop</Link></li>
        <li><Link to="/submit">Submit</Link></li>
      </ul>     
    </div>

    <div>
        <Routes>
          <Route path="/about" element={<About/>} />
          <Route path="/submit" element={<Submit/>} />
          <Route path="/shop" element={<Shop/>} />
          <Route path="/" element={<Home/>} />
        </Routes>      
      </div>
      {/* <Footer/> */}
  </>
  );
}

export default App;
