import React from "react";
// import preorder from '../imgs/preorderBanner.png';


export default function Shop () {

    return (
        <div className='grid justify-items-center'>
            <div className='flex justify-center'>
            {/* <img alt='preorder now' src={preorder} style={{ width: '85%', height: 'auto', padding: '20px', borderRadius: '50px' }} /> */}
            </div>
            <br/>
        </div>   

    
    )
}
