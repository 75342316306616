import cake from '../imgs/cake.jpg';
import '../index.css'
import marquee from '../imgs/availableMarquee.gif';
import mobileMarquee from '../imgs/availableMobile.gif';
import IGcollage from '../imgs/IGcollage.jpg';
import stickers1 from '../imgs/stickers1.png';
import stickers2 from '../imgs/stickers2.png';
import preorder from '../imgs/availBanner.png';
import cringe from '../imgs/cringe.png'


import {
  Link
} from "react-router-dom";

export default function Home () {
    return (
        <>
        {/* <div>
        <img
          classname="marquee"
          src={marquee}
          alt="preorders open 7/26"
          style={{
            width: '100%',  // Set the image to occupy the full width of the container
            transform: 'scale(1)',  // Slightly zoom in the image (adjust the scale factor as needed)
          }}
        />
      </div> */}
      <div>
        {/* Marquee for desktop */}
        <div className="marquee-desktop">
          <img src={marquee} alt="preorders open 7/26" />
        </div>

        {/* MobileMarquee for mobile screens (max-width: 768px) */}
        <div className="marquee-mobile">
          <img src={mobileMarquee} alt="preorders open 7/26" />
        </div>
      </div>

            <div className="hero min-h-screen" style={{ backgroundImage: `url(${cake})`}}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className="hero-content text-center text-neutral-content">
                <div className="max-w-md">
                <h1 className="mb-5 text-5xl font-bold">we're so happy you're here!</h1>
                <h1 className="mb-5">You might be thinking to yourself…Popular Girl? Is this really for me? I wasn't “popular” or “I'm not a girl”, but we want to assure you that if you're reading this… 
                    <br/>
                    <br/>
                    <strong>YOU are a popular girl, girl!</strong>
                    <br/>
                    Popular girl is a vibe.
                    <br/>
                    Popular girl is a state of mind.
                    <br/>
                    Popular girl is he/him, she/her, they/them, et al 
                    <br/>
                    Popular girl is feminist and intersectional. 
                    <br/>
                </h1>
                <button className="btn btn-primary"> <Link to="/about">More about us</Link></button>
                </div>
            </div>
            </div>
            <a href="/shop">
            <div className='flex justify-center'>
              <img alt='preorder now' src={preorder} style={{ width: '85%', height: 'auto', padding: '20px', borderRadius: '50px' }} />
            </div>
          </a>


            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-1 md:col-span-1 hidden md:block" style={{padding: '15px'}}>
              <img alt='stickers' src={stickers2}/>
            </div>
            <div className="col-span-5 md:col-span-5 " style={{padding: '30px'}}>
              {/* Content of the first column */}
              {/* <div className="flex items-center h-[600px]"> */}
              <article className="prose prose-slate justify-center">
                <p>Like most things in life, Popular Girl is better with a soundtrack. We've curated a playlist so bitchin' that it cannot be contained to one streaming platform so we've included links for both Spotify and Tidal. </p>
                <p>Inspired by epic road trips, heartfelt conversations, and a shit ton of nostalgia…we hope you enjoy 'Now That's What I Call Friendship'</p>
              </article>
              <br/>
              <iframe title='spotify'style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/playlist/7xJ74xBlrv9jmWmtuqnPRy?utm_source=generator&theme=0" width="85%" height="160" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
              <iframe title='tidal' style={{ borderRadius: '12px' }} src="https://embed.tidal.com/playlists/24deca54-191b-4fe4-86a8-c6b8826509ec?" allowFullScreen="" frameBorder="0" width="85%" height="140"></iframe>
              {/* </div> */}
            </div>
            <div className="col-span-5 md:col-span-5 " style={{padding: '15px'}}>
              {/* Content of the second column */}
              <article className="prose prose-slate">
                <a className="link link-hover" href='https://instagram.com/populargirlpress' target="_blank" rel="noopener noreferrer"><h1>follow us on IG <ion-icon name="logo-instagram" /></h1></a>
              </article>
              <a href='https://instagram.com/populargirlpress'><img alt='ig collage' src={IGcollage} style={{ borderRadius: '25px' }}/></a>
            </div>
            <div className="col-span-1 md:col-span-1 hidden md:block" style={{padding: '15px', transform: 'translateY(50%)' }}>
              <img alt='stickers' src={stickers1}/>
            </div>
          </div>
          <img alt='united in cringe and sincerity' src={cringe} style={{ width: 'auto', height: 'auto' }} />
          <br/>
        </>
    )
  }
  
  