import girlies from '../imgs/KandLB.jpg';
import girliesMobile from '../imgs/KandLBmobile.jpg';
import wiggle from '../imgs/wiggle.png';

export default function About () {
    return (
      <>
      <div className="container mx-auto grid grid-cols-3 gap-8" style={{ paddingTop: 30 }}>
      <div className="col-span-1 girlies-desktop" style={{ '@media (max-width: 768px)': { display: 'none' } }}>
        <img src={girlies} alt="Krista and Lauren" style={{ borderRadius: '5%', width: '100%', height: 'auto' }} />
      </div>
    
      <div className="col-span-2 justify-center about-mobile" style={{ '@media (max-width: 768px)': { width: '100%' } }}>
        <div className='girlies-mobile' style={{ width: '150%' }}>
          <img src={girliesMobile} alt="Krista and Lauren" style={{ borderRadius: '5%' }} />
        </div>
        <article className="prose" style={{ width: '150%' }}>
            <p>Popular Girl is a party and everyone's invited. Inspired by teen magazines of the past but with a modern twist; Popular Girl celebrates the carefree joy and messy drama of growing up through essays, photography, illustrations, diary entries, and more. </p>
            <p>Grab your glitter pen and get ready to meet the girls, play some MASH, have passionate discussions about silly topics, and unite in the cringe and sincerity of our youth.</p>
            <h3>Krista Esse</h3>
            <p className='italic'>Co Founder and Art Director</p>
            <p>Krista is a graphic designer and illustrator based in Tucson, Arizona. In addition to designing Popular Girl Press, she works as a freelance designer for startups in the food and beverage space. She's passionate about 90's rom coms, maximalist home decor, collecting vintage cat figurines, and consuming way too much caffeine.</p>
            <h3>Lauren Harris</h3>
            <p className='italic'>Co Founder and Editor in Chief</p>
            <p>Lauren is a silly little goose and pop culture aficionado originally from Fargo but currently residing in Tucson. When she's not working on Popular Girl she can be found watching the same 10 movies over and over again, giggling at her own reviews on Letterboxd, hyper fixating on a new craft project, and hanging out with her cat sons, Honey and Baby #boymom.
            She is passionate about Taco Bell, thrift karma, Halloween, and convincing her friends to watch movies she swears they will love.</p>
          </article>

          <div className="collapse bg-base-200" >
          <input type="checkbox" /> 
          <div className="collapse-title text-s font-medium">
          need more info?
          </div>
          <div className="collapse-content "> 
          <p className='text-xs'>email us! <a className="link link-hover" href="mailto:populargirlpress@gmail.com">populargirlpress@gmail.com</a></p>
          <p className='text-xs'>need a bangin website like this one? <a className="link link-hover" href='mailto:gabygalv.codes@gmail.com'>gabygalv.codes@gmail.com</a> </p>  </div>
        </div>
        </div>
      </div>

      <br/>
      
      {/* <div className="flex justify-center items-center">
      <details className="dropdown dropdown-end mb-32">
  <summary className="m-1 btn">need more info?</summary>
  <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
    <li>
    <p className='text-xs'>email us! <a className="link link-hover" href="mailto:populargirlpress@gmail.com">populargirlpress@gmail.com</a></p>
            <p className='text-xs'>need a bangin website like this one? <a className="link link-hover" href='mailto:gabygalv.codes@gmail.com'>gabygalv.codes@gmail.com</a> </p>
    </li>
  </ul>
</details>
</div> */}
            {/* <p>Copyright © 2023 - All right reserved by Popular Girl Press</p> */}


      <img alt='divider' src={wiggle} style={{ width: 'auto', height: 'auto' }} />
      <br/>
      </>
    )
}
